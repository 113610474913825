import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import RepairManualCardUtil,{IManualCardDataObj} from './repairManualCardUtil';
export default defineComponent({
    name:'ManualCard',
    title:'操作手册',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:IManualCardDataObj=reactive<IManualCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: utils.ToolsProviderApi.buildUrl('/repairManual')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                cxId: [utils.UtilPub.commonValidRule('请选择所属车型')],
                name: [utils.UtilPub.commonValidRule('请输入手册名称')]
            },
            cxData:[]
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new RepairManualCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'cxId'==params.comboId){
                    return dataObj.cxData
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('cxId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.form.id,
                    fileSize:100,
                    saveType:1,
                    limitNum:1,
                    accept:'.pdf,.PDF',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params);
                }
            });
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,buildUploadInitParams,handlePreview

        }
    }
});